const FALLBACK_ASPECT = 9 / 16;
const SLIDE_CONTROLS_HEIGHT = 56;
const DELAY_FOR_VISIBILITY = 100;
const WRAPPER_CLASS = 'groupfile-iframe-wrapper';
const IFRAME_STYLES = {
    border    : 0,
    display   : 'block',
    margin    : '0 auto',
    padding   : 0,
    visibility: 'hidden',
};
class Handler {
    scriptElm;
    wrapperElm;
    iframeElm;
    aspect;
    media;
    hostname;
    controlsHeight;
    heightOffset;
    widthOffset;
    /**
     * The Handler constructor.
     *
     * @param script - A script element to handle.
     */
    constructor( script ) {
      this.scriptElm = script;
      this.init();
    }
  
    /**
     * Initializes the handler.
     */
    init() {
      const script = this.scriptElm;
  
      this.media          = script.getAttribute( 'data-media' ) == "video" ? "video" : "slide";

      this.aspect         = +script.getAttribute( 'data-aspect' ) || FALLBACK_ASPECT;
      this.controlsHeight = +script.getAttribute( 'data-controls-height' ) || this.media == "video" ? 0 : SLIDE_CONTROLS_HEIGHT;
      this.widthOffset    = +script.getAttribute( 'data-width-offset' ) || 0;
      this.heightOffset   = +script.getAttribute( 'data-height-offset' ) || 0;
  
      const src = script.getAttribute( 'data-src' );
  
      if ( src ) {
        this.embed( src );
        this.resize();
        window.addEventListener( 'resize', this.resize.bind( this ) );
        window.addEventListener( 'load', this.resize.bind( this ) );
      } else {
        console.error( `[Groupfile] "data-src" is missing.` );
      }
  
      this.removeScript();
    }
  
    /**
     * Embeds the created iframe and removes the script tag.
     *
     * @param src - A URL to the slide to show.
     */
    embed( src ) {
      this.create( src );
      this.scriptElm.parentElement.insertBefore( this.wrapperElm, this.scriptElm );
      Object.defineProperty( this.iframeElm, 'handler', { value: this } );
    }
  
    /**
     * Creates a wrapper and an iframe element to embed.
     *
     * @param src - A URL to the slide to show.
     */
    create( src ){
      const iframe = document.createElement( 'iframe' );
      iframe.setAttribute("src",src);
      iframe.setAttribute("allowfullscreen",'true');
      this.styles( iframe, IFRAME_STYLES );
  
      iframe.addEventListener ( 'load', () => {
        setTimeout( () => { iframe.style.visibility = 'visible' }, DELAY_FOR_VISIBILITY );
      } );
  
      const wrapper = document.createElement( 'div' );
      wrapper.classList.add( WRAPPER_CLASS );
      wrapper.appendChild( iframe );
  
      this.iframeElm  = iframe;
      this.wrapperElm = wrapper;
    }

    styles( elm, styles ){
        Object.keys(styles).forEach( key => (elm.style[ key ] = String( styles[ key ] )));
    }
    

    /**
     * Resizes the iframe element corresponding with the viewport dimension.
     */
    resize() {
      const iframe         = this.iframeElm;
      const viewportWidth  = this.wrapperElm.clientWidth + this.widthOffset;
      const viewportHeight = document.documentElement.clientHeight - this.controlsHeight + this.heightOffset;
      const floor          = Math.floor;
  
      if ( viewportWidth ) {
        if ( viewportHeight / viewportWidth > this.aspect ) {
          iframe.width  = String( viewportWidth );
          iframe.height = String( floor( viewportWidth * this.aspect ) + this.controlsHeight );
        } else {
          iframe.width  = String( floor( viewportHeight / this.aspect ) );
          iframe.height = String( viewportHeight + this.controlsHeight );
        }
      }
    }
  
    /**
     * Removes the script element from the DOM tree.
     */
    removeScript() {
      this.scriptElm.parentElement.removeChild( this.scriptElm );
    }
}
class Embed {
    constructor() {
        const scripts = document.querySelectorAll( ".groupfile-embed" );
        for ( let i = 0; i < scripts.length; i++ ) {
            new Handler( scripts[ i ] );
        }
    }
}
//styles, create, on, error, attr

new Embed();